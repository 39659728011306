<template>
  <div class="componentSty">
    <div class="componentSty_content">
      <div class="componentSty_content_title">
        <el-form inline size="small">
          <el-form-item label="车牌号码">
            <el-input
              placeholder="请输入关键词"
              v-model="conditions.plate"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item label="挂车号码">
            <el-input
              placeholder="请输入关键词"
              v-model="conditions.hangPlate"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item label="司机名称">
            <el-input
              placeholder="请输入关键词"
              v-model="conditions.driverName"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item label="车辆所有人">
            <el-input
              placeholder="请输入关键词"
              v-model="conditions.carowner"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <el-select v-model="conditions.useStatus" clearable multiple>
              <el-option
                v-for="(item, index) in enumStatus"
                :key="index"
                :value="index"
                :label="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              size="small"
              class="buttons"
              @click="handleSearch"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="componentSty_content_table">
        <el-table border :data="tableData" style="width: 100%">
          <el-table-column
            show-overflow-tooltip
            label="序号"
            type="index"
            width="50px"
            :index="indexMethod"
          ></el-table-column>
          <el-table-column show-overflow-tooltip prop="plate" label="车牌号码">
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            prop="hangPlate"
            label="挂车号码"
          >
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            prop="driverName"
            label="司机姓名"
          >
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            prop="driverPhone"
            label="司机手机号"
          >
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            prop="carowner"
            label="车辆所有人"
          >
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            prop="discharge"
            label="排放标准"
          >
          </el-table-column>
          <el-table-column show-overflow-tooltip label="限定载重">
            <template v-slot="res">
              <span>{{
                res.row.truckTypecode && res.row.truckTypecode + "吨"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="审核状态">
            <template v-slot="scope">
              <el-tag
                v-if="enumStatus[scope.row.useStatus]"
                size="small"
                :type="enumStatus[scope.row.useStatus]?.type"
                >{{ enumStatus[scope.row.useStatus]?.label }}</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template v-slot="scope">
              <el-button
                type="text"
                size="mini"
                @click.stop="goDetail(scope.row)"
              >
                详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          :total="total"
          :page="conditions.page"
          :pageSize="conditions.limit"
          @paging="getPaging"
        />
      </div>
    </div>
  </div>
</template>
<script>
import lodash from "lodash";
import Pagination from "@/components/pagination.vue";
import { mapState } from "vuex";
export default {
  data() {
    return {
      enumStatus: {
        0: { label: "待审核", type: "warning" },
        1: { label: "正常", type: "success" },
        2: { label: "审核未通过", type: "danger" },
        3: { label: "停用", type: "info" },
      },
      conditions: {
        page: 1,
        limit: 10,
        useStatus: ["0"],
      },
      total: 0,
      tableData: [],
    };
  },
  // computed: {
  //   ...mapState(["conditionsList"]),
  // },
  components: {
    Pagination,
  },
  computed: {
    ...mapState(["pendingCount"]),
  },
  created() {
    this.getList();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.getPendingCount();
    });
  },
  methods: {
    indexMethod(index) {
      return (this.conditions.page - 1) * this.conditions.limit + index + 1;
    },
    getPaging(val) {
      this.conditions.page = val.page;
      this.conditions.limit = val.pageSize;
      this.getList();
    },
    // ...mapMutations(["createConditions"]),
    // 获取列表数据
    async getList(num) {
      typeof num === "number" && (this.conditions.page = num);
      const { data } = await this.$http(
        "api1",
        "/api/biz/TrunkInfo/list",
        "get",
        this.transformConditions()
      );
      if (data.code != 0) return this.$message.error(data.msg);
      this.tableData = data.data.list;
      this.total = data.data.totalCount;
    },
    transformConditions() {
      let clone = lodash.cloneDeep(this.conditions);
      clone.useStatus = clone.useStatus.toString();
      return clone;
    },
    handleSearch() {
      this.conditions.page = 1;
      this.getList();
    },
    goDetail(row) {
      this.$router.push({
        path: "/check/driverVehicleDetails",
        query: { id: row.id },
      });
    },
    async getPendingCount() {
      const res = await this.$http("api1", "/api/stat/pendingNum", "get");
      for (let item of this.pendingCount) {
        item.count = res.data[item.key];
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
